import React, { useState, useEffect } from 'react';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Popup from './components/common/popup/Popup';
import { NavProvider } from './contexts/NavContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import browserLang from 'browser-lang';
import { useCookies } from 'react-cookie';

export const headerVariant = {
  hidden: { opacity: 0, transition: { duration: 0.5 } },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const App = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { i18n } = useTranslation();
  const defaultLang = browserLang({ languages: ['en', 'pl'], fallback: 'en' });
  const [cookies, setCookie] = useCookies(['lang']);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleConfirm = () => {
    changeLanguage('pl');
    setShowPopup(false);
  };

  useEffect(() => {
    if (!cookies.lang) {
      axios
        .get('https://ipapi.co/json')
        .then((response) => {
          const country = response.data.country;
          if (country === 'PL' || defaultLang === 'pl') {
            setShowPopup(true);
          } else {
            changeLanguage('en');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [cookies.lang, defaultLang]);

  useEffect(() => {
    if (['en', 'pl'].includes(i18n.language)) {
      i18n.changeLanguage(i18n.language);
      setCookie('lang', i18n.language, { path: '/' });
    }
  }, [i18n.language, i18n, setCookie]);
  return (
    <NavProvider>
      <Header />
      <Popup isOpen={showPopup} onClose={() => setShowPopup(false)} onConfirm={handleConfirm} />
      <Nav />
      <About />
      <Experience />
      <Portfolio />
      <Contact />
      <Footer />
    </NavProvider>
  );
};

export default App;
