import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const socialVariant = {
  scaled: {
    scale: 2,
    transition: { delay: 1.5, repeat: 1, duration: 0.5, repeatType: 'reverse' }
  }
};

const HeaderSocials = () => {
  const { i18n } = useTranslation();
  return (
    <div className='header__socials'>
      {i18n.language === 'en' ? (
        <motion.a
          href='https://www.linkedin.com/in/kamilwozniak158/?locale=en_US'
          target='_blank'
          rel='noreferrer'
          variants={socialVariant}
          animate='scaled'>
          <BsLinkedin />
        </motion.a>
      ) : (
        <motion.a
          href='https://www.linkedin.com/in/kamilwozniak158/'
          target='_blank'
          rel='noreferrer'
          variants={socialVariant}
          animate='scaled'>
          <BsLinkedin />
        </motion.a>
      )}
      <motion.a
        href='https://github.com/WoXuS'
        target='_blank'
        rel='noreferrer'
        variants={socialVariant}
        animate='scaled'>
        <BsGithub />
      </motion.a>
    </div>
  );
};

export default HeaderSocials;
