import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './about.css';
import ME from '../../assets/Kamil Woźniak hs.jpg';
import { FaAward } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import { motion } from 'framer-motion';
import { NavContext } from '../../contexts/NavContext';
import { headerVariant } from '../../App';
import { useAnimateOnce } from '../../hooks/useAnimateOnce';

const aboutImg = {
  hidden: { transition: { duration: 0.5 } },
  visible: { rotate: 10, transition: { duration: 0.5 } }
};
const aboutArticle = {
  hidden: { opacity: 0, y: -100, transition: { duration: 0.5 } },
  visible: { opacity: 1, y: 0 }
};

const cards = [
  { icon: <FaAward className='about__icon' />, titleKey: 'about__experience1', descKey: 'about__experience2' },
  { icon: <FaGraduationCap className='about__icon' />, titleKey: 'about__education1', descKey: 'about__education2' },
  { icon: <VscFolderLibrary className='about__icon' />, titleKey: 'about__projects1', descKey: 'about__projects2' }
];

const About = () => {
  const { handleClick, activeNav } = useContext(NavContext);
  const { t } = useTranslation();
  const isVisible = activeNav === '#about';
  const animateProp = useAnimateOnce(isVisible);

  return (
    <section id='about'>
      <motion.h5 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('about__small-header')}
      </motion.h5>
      <motion.h2 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('about__header')}
      </motion.h2>
      <div className='container about__container'>
        <div className='about__me'>
          <motion.div
            className='about__me-image'
            variants={aboutImg}
            initial='hidden'
            animate={animateProp}
            whileHover={{ rotate: 0, transition: { duration: 0.5 } }}>
            <img src={ME} alt='Kamil Woźniak' />
          </motion.div>
        </div>
        <div className='about__content'>
          <div className='about__cards'>
            {cards.map((card, i) => (
              <motion.article
                className='about__card'
                variants={aboutArticle}
                initial='hidden'
                animate={isVisible ? 'visible' : 'hidden'}
                transition={{ delay: 0.5 + i * 0.2, type: 'spring', stiffness: 50 }}
                key={i}>
                {card.icon}
                <h5>{t(card.titleKey)}</h5>
                <small>{t(card.descKey)}</small>
              </motion.article>
            ))}
          </div>
          <div>
            <p>{t('about__bio1')}</p>
            <p>{t('about__bio2')}</p>
            <p>{t('about__bio3')}</p>
            <p>{t('about__bio4')}</p>
          </div>
          <div className='about__cta'>
            <a href='#portfolio' onClick={(event) => handleClick(event, '#portfolio')} className='btn btn-primary'>
              Portfolio
            </a>
            <a href='#contact' onClick={(event) => handleClick(event, '#contact')} className='btn btn-primary'>
              {t('about__contact-btn')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
