import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CV_EN from '../../assets/Kamil Woźniak CV EN.pdf';
import CV_PL from '../../assets/Kamil Woźniak CV PL.pdf';
import { ReactComponent as PolishFlag } from '../../assets/PL_FLAG.svg';
import { ReactComponent as EnglishFlag } from '../../assets/EN_FLAG.svg';
import { NavContext } from '../../contexts/NavContext';

const CTA = () => {
  const { handleClick } = useContext(NavContext);
  const { i18n, t } = useTranslation();

  return (
    <div className='cta'>
      {i18n.language === 'en' ? (
        <a href={CV_EN} download className='btn'>
          Download CV <EnglishFlag width={25} height={15} className='flag' />
        </a>
      ) : (
        <a href={CV_PL} download className='btn'>
          Pobierz CV <PolishFlag width={25} height={15} className='flag' />
        </a>
      )}
      <a href='#contact' onClick={(event) => handleClick(event, '#contact')} className='btn btn-primary'>
        {t('contact-btn')}
      </a>
    </div>
  );
};

export default CTA;
