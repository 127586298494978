import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './header.css';
import CTA from './CTA';
import profilepic from '../../assets/Kamil Woźniak header.png';
import HeaderSocials from './HeaderSocials';
// import LanguageSelector from '../language-selector/LanguageSelector';
import { NavContext } from './../../contexts/NavContext';

const headerVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.5, duration: 1 } }
};

const ctaVariant = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { delay: 1, type: 'spring', stiffness: 50 } }
};

const meVariant = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { delay: 1.5, type: 'spring' } }
};

const scrollVariant = {
  scaled: {
    scale: 2,
    transition: { delay: 1.5, repeat: 1, duration: 0.5, repeatType: 'reverse' }
  }
};

const Header = () => {
  const { handleClick } = useContext(NavContext);
  const { t } = useTranslation();
  return (
    <header id='home'>
      <div className='container header__container'>
        <div className='header__left-column'>
          <motion.h5 variants={headerVariant} initial='hidden' animate='visible'>
            {t('hello')}
          </motion.h5>
          <motion.h1 variants={headerVariant} initial='hidden' animate='visible'>
            Kamil Woźniak
          </motion.h1>
          <motion.h5 className='text-green' variants={headerVariant} initial='hidden' animate='visible'>
            Front-End Developer
          </motion.h5>
          <p className='text-light'>{t('header__text')}</p>
          <motion.div variants={ctaVariant} initial='hidden' animate='visible'>
            <CTA />
          </motion.div>
          {/* <LanguageSelector /> */}
        </div>
        <div className='header__right-column'>
          <motion.div className='header__me' variants={meVariant} initial='hidden' animate='visible'>
            <img src={profilepic} alt='' />
          </motion.div>
        </div>
        <HeaderSocials />
        <motion.a
          href='#contact'
          onClick={(event) => handleClick(event, '#contact')}
          className='scroll__down'
          style={{ rotate: 90 }}
          variants={scrollVariant}
          animate='scaled'>
          {t('scroll-down')}
        </motion.a>
      </div>
    </header>
  );
};

export default Header;
