import React, { useContext } from 'react';
import './portfolio.css';
import portfolioItems from './portfolioItems';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { NavContext } from '../../contexts/NavContext';
import { headerVariant } from '../../App';
import { useAnimateOnce } from '../../hooks/useAnimateOnce';

const portfolioArticle = {
  hidden: { opacity: 0, y: -100, transition: { duration: 0.5 } },
  visible: { opacity: 1, y: 0 }
};

const Portfolio = () => {
  const { activeNav } = useContext(NavContext);
  const { t } = useTranslation();
  const isVisible = activeNav === '#portfolio';
  const animateProp = useAnimateOnce(isVisible);
  return (
    <section id='portfolio'>
      <motion.h5 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('portfolio__small-header')}
      </motion.h5>
      <motion.h2 variants={headerVariant} initial='hidden' animate={animateProp}>
        Portfolio
      </motion.h2>
      <div className='container portfolio__container'>
        {portfolioItems.map((portfolioItem, i) => {
          return (
            <motion.article
              className='portfolio__item'
              key={portfolioItem.id}
              variants={portfolioArticle}
              initial='hidden'
              animate={animateProp}
              transition={{ delay: 0.5 + (i - 1) * 0.2 }}>
              <div className='portfolio__overlay'>
                <p>{t(`portfolio__${portfolioItem.id}-description`)}</p>
              </div>
              <div className='portfolio__item-image'>
                <img src={portfolioItem.image} alt={`${t(`portfolio__${portfolioItem.id}-title`)} screenshot`} />
              </div>
              <h3>{t(`portfolio__${portfolioItem.id}-title`)}</h3>
              <div className='portfolio__item-cta'>
                <a href={portfolioItem.github} className='btn' target='_blank' rel='noreferrer'>
                  GitHub
                </a>
                {portfolioItem.demo && (
                  <a href={portfolioItem.demo} className='btn btn-primary' target='_blank' rel='noreferrer'>
                    {portfolioItem.title === 'Sound-Picture Quiz' ? 'APK' : t('portfolio__live-demo')}
                  </a>
                )}
              </div>
            </motion.article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
