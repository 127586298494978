import React from 'react';
import './Loader.css';
import LoaderGif from '../../../assets/loader.gif';

const Loader = () => {
  return (
    <div className='loader-container'>
      <img className='loader' src={LoaderGif} alt='loader' />
    </div>
  );
};

export default Loader;
