import RegistrationModule from '../../assets/registration-module.png';
import Mustang from '../../assets/mustang-screenshot.jpg';
import GnNews from '../../assets/gnnews-screenshot.jpg';
import Dashboard from '../../assets/dashboard-screenshot.jpg';
import SoundPictureQuiz from '../../assets/sound-picture-quiz.jpg';
import GeekDate from '../../assets/geek-date.jpg';

const portfolioItems = [
  {
    id: 1,
    image: RegistrationModule,
    title: 'Registration Module',
    github: 'https://github.com/WoXuS/registration-module',
    demo: 'https://registration-module.wozniakkamil.com',
    description:
      'This project features a two-step registration form with various required fields and an optional one, on top of a summary window to review the inputted information.'
  },
  {
    id: 2,
    image: Dashboard,
    title: 'Shopping Carts Dashboard',
    github: 'https://github.com/WoXuS/dashboard-app',
    demo: 'https://dashboard-app.wozniakkamil.com',
    description:
      'A simple dashboard application in React that displays mock data for shopping carts from the https://dummyjson.com/docs/carts API. Most of the components in the app were written from scratch.'
  },
  {
    id: 3,
    image: GnNews,
    title: 'World News App',
    github: 'https://github.com/WoXuS/gn-news',
    demo: 'https://gn-news.wozniakkamil.com',
    description:
      "A news application built using React and MUI, fetching news data from https://newsapi.org/v2. Unfortunately the free plan doesn't allow for browser request on production so the app isn't fully functional."
  },
  {
    id: 4,
    image: Mustang,
    title: 'Interactive Mustang Model',
    github: 'https://github.com/WoXuS/interactive-mustang-model',
    demo: 'https://interactive-mustang.wozniakkamil.com',
    description:
      'An interactive Ford Mustang model built using Three.js. The application allows users to change the colors and textures of the brake calipers and wheels.'
  },
  {
    id: 5,
    image: SoundPictureQuiz,
    title: 'Sound-Picture Quiz',
    github: 'https://github.com/WoXuS/sound-picture-quiz',
    demo: 'https://github.com/WoXuS/sound-picture-quiz/blob/main/SoundPictureQuiz.apk',
    description:
      'A college project designed to help disabled children learn the sounds of different animals. The app is built using React Native, featuring two game modes: Training and "1 sound 4 pictures".'
  },
  {
    id: 6,
    image: GeekDate,
    title: 'GeekDate',
    github: 'https://github.com/WoXuS/geek-date',
    demo: '',
    description:
      'A web application in React that acts as a social media platform for gamers to look for company to play.'
  }
];

export default portfolioItems;
