import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import './Popup.css';

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

const modal = {
  hidden: { y: '-100vh', opacity: 0 },
  visible: { y: '0', opacity: 1, transition: { delay: 0.5 } }
};

const Popup = ({ isOpen, onClose, onConfirm }) => {
  return (
    <AnimatePresence mode='wait'>
      {isOpen && (
        <motion.div className='popup__backdrop' variants={backdrop} initial='hidden' animate='visible' exit='hidden'>
          <motion.div className='popup__content' variants={modal}>
            <h2>Hej! Zauważyłem że jesteś z Polski</h2>
            <h4 className='text-light'>Czy chciałbyś wyświetlić stronę po polsku?</h4>
            <div className='popup__button-group'>
              <button className='btn btn-primary' onClick={onConfirm}>
                Tak
              </button>
              <button className='btn' onClick={onClose}>
                Nie
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default Popup;
