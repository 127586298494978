import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';

import './language-selector.css';
import PropTypes from 'prop-types';

const LanguageSelector = ({ icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language === 'English' ? 'en' : 'pl');
    setSelectedLanguage(i18n.language);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const languageName = (code) => {
    return code === 'en' ? 'English' : 'Polski';
  };

  return (
    <>
      <div
        className={`language-selector${isOpen ? ' open' : ''}${icon ? ' icon' : ''}`}
        onClick={() => setIsOpen(!isOpen)}>
        <div className='selected-language'>
          {icon ? <TranslateIcon /> : languageName(selectedLanguage)}
          {!icon && <i className={`arrow${isOpen ? ' open' : ''}`}></i>}
        </div>

        <ul className='language-options'>
          <li onClick={() => handleLanguageSelect('English')} className={selectedLanguage === 'en' ? 'active' : ''}>
            English
          </li>
          <li onClick={() => handleLanguageSelect('Polski')} className={selectedLanguage === 'pl' ? 'active' : ''}>
            Polski
          </li>
        </ul>
      </div>
    </>
  );
};

export default LanguageSelector;

LanguageSelector.propTypes = {
  icon: PropTypes.bool
};
