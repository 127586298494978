import React, { useState, useContext } from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { RiMessengerLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { NavContext } from '../../contexts/NavContext';
import { headerVariant } from '../../App';
import { useAnimateOnce } from '../../hooks/useAnimateOnce';
import Form from './Form';

const contactOption = {
  hidden: { opacity: 0, x: -100, transition: { duration: 1 } },
  visible: { opacity: 1, x: 0, transition: { duration: 1, type: 'spring', stiffness: 50 } }
};

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { activeNav } = useContext(NavContext);
  const { t } = useTranslation();
  const isVisible = activeNav === '#contact';
  const animateProp = useAnimateOnce(isVisible);

  return (
    <section id='contact'>
      <motion.h5 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('contact__small-header')}
      </motion.h5>
      <motion.h2 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('contact__header')}
      </motion.h2>
      <div className='container contact__container'>
        <div className='contact__options'>
          <a href='mailto:kamilwozniak158@gmail.com' target='_blank' rel='noreferrer'>
            <motion.article
              className='contact__option'
              variants={contactOption}
              initial='hidden'
              animate={isVisible ? 'visible' : 'hidden'}>
              <MdOutlineEmail className='contact__option-icon' />
              <h4>Email</h4>
              <h5>kamilwozniak158@gmail.com</h5>
              <p className='contact__option-btn'>{t('contact__send-message')}</p>
            </motion.article>
          </a>
          <a href='https://m.me/kamil.wozniak.5011' target='_blank' rel='noreferrer'>
            <motion.article
              className='contact__option'
              variants={contactOption}
              initial='hidden'
              animate={isVisible ? 'visible' : 'hidden'}>
              <RiMessengerLine className='contact__option-icon' />
              <h4>Messenger</h4>
              <h5>Kamil Woźniak</h5>
              <p className='contact__option-btn'>{t('contact__send-message')}</p>
            </motion.article>
          </a>
        </div>
        <Form
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
        />
      </div>
    </section>
  );
};

export default Contact;
