import React, { useContext } from 'react';
import './footer.css';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { NavContext } from '../../contexts/NavContext';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { handleClick } = useContext(NavContext);
  const { t, i18n } = useTranslation();

  return (
    <footer>
      <a href='#' className='footer__logo' onClick={(event) => handleClick(event, '#')}>
        Kamil Woźniak
      </a>
      <ul className='permalinks'>
        <li>
          <a href='#' onClick={(event) => handleClick(event, '#')}>
            {t('footer__home')}
          </a>
        </li>
        <li>
          <a href='#about' onClick={(event) => handleClick(event, '#about')}>
            {t('footer__about')}
          </a>
        </li>
        <li>
          <a href='#experience' onClick={(event) => handleClick(event, '#experience')}>
            {t('footer__experience')}
          </a>
        </li>
        <li>
          <a href='#portfolio' onClick={(event) => handleClick(event, '#portfolio')}>
            {t('footer__portfolio')}
          </a>
        </li>
        <li>
          <a href='#contact' onClick={(event) => handleClick(event, '#contact')}>
            {t('footer__contact')}
          </a>
        </li>
      </ul>
      <div className='footer__socials'>
        {i18n.language === 'en' ? (
          <a href='https://www.linkedin.com/in/kamilwozniak158/?locale=en_US' target='_blank' rel='noreferrer'>
            <BsLinkedin />
          </a>
        ) : (
          <a href='https://www.linkedin.com/in/kamilwozniak158/' target='_blank' rel='noreferrer'>
            <BsLinkedin />
          </a>
        )}
        <a href='https://github.com/WoXuS' target='_blank' rel='noreferrer'>
          <BsGithub />
        </a>
      </div>
      <div className='footer__copyright'>
        <small>{t('footer__copyright')}</small>
      </div>
    </footer>
  );
};

export default Footer;
