import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const NavContext = React.createContext();

export const NavProvider = ({ children }) => {
  const [activeNav, setActiveNav] = useState('#');
  const [sections, setSections] = useState([]);

  const calculateSections = () => {
    setTimeout(() => {
      const sectionIds = ['home', 'about', 'experience', 'portfolio', 'contact'];
      setSections(
        sectionIds.map((id) => {
          const element = document.querySelector(`#${id}`);
          const rect = element.getBoundingClientRect();
          const top = window.scrollY + rect.top;
          const height = rect.height;
          return {
            id: `#${id}`,
            start: top,
            end: top + height
          };
        })
      );
    }, 500);
  };

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  useEffect(() => {
    const handleScroll = () => {
      calculateSections();
      const currentScrollY = window.scrollY + window.innerHeight / 2;
      for (let section of sections) {
        if (currentScrollY > section.start && currentScrollY <= section.end) {
          setActiveNav(section.id);
          break;
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [sections]);

  const handleClick = (event, sectionId) => {
    event.preventDefault();
    if (sectionId === '#') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const section = document.querySelector(sectionId);
      window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }
    setActiveNav(sectionId);
  };

  return <NavContext.Provider value={{ activeNav, setActiveNav, handleClick }}>{children}</NavContext.Provider>;
};

NavProvider.propTypes = {
  children: PropTypes.node.isRequired
};
