import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Accordion.css';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = ({ item, expanded, onClick, last, isVisible }) => {
  const { t } = useTranslation();

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 }
  };

  const arrowVariant = {
    closed: {
      rotate: 0,
      scale: 1,
      transition: { delay: 0 },
      transformOrigin: 'center'
    },
    open: {
      rotate: 180,
      scale: 1,
      transition: { delay: 0 },
      transformOrigin: 'center'
    },
    scaled: {
      scale: 2.2,
      transition: { delay: 1.5, repeat: 1, duration: 0.75, repeatType: 'reverse' }
    }
  };

  return (
    <article
      className={`accordion__details ${item.id === 0 ? 'first-of-type' : ''} ${
        item.id === last ? 'last-of-type' : ''
      } ${expanded ? 'expanded' : ''}`}>
      <div>
        <h4 onClick={onClick}>
          {item.title}
          <motion.div
            className='accordion__arrow-wrapper'
            variants={arrowVariant}
            initial='closed'
            animate={isVisible === 'visible' ? 'scaled' : ''}>
            <motion.div
              className='accordion__arrow-wrapper'
              variants={arrowVariant}
              initial='closed'
              animate={expanded ? 'open' : 'closed'}>
              <ExpandMoreIcon className='accordion__arrow' />
            </motion.div>
          </motion.div>
        </h4>
        <motion.small
          className={`text-light`}
          initial='closed'
          animate={expanded ? 'open' : 'closed'}
          variants={variants}>
          {t(item.description)}
        </motion.small>
      </div>
    </article>
  );
};

export default Accordion;

Accordion.propTypes = {
  item: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  last: PropTypes.number.isRequired,
  isVisible: PropTypes.string.isRequired
};
