import { useEffect, useState } from 'react';

export const useAnimateOnce = (isVisible) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setHasBeenVisible(true);
    }
  }, [isVisible]);

  return hasBeenVisible ? 'visible' : 'hidden';
};
