import React, { useContext, useState } from 'react';
import './experience.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { NavContext } from '../../contexts/NavContext';
import { headerVariant } from '../../App';
import { useAnimateOnce } from '../../hooks/useAnimateOnce';
import Accordion from '../common/accordion/Accordion';
import { frontendItems, otherItems } from './experienceItems';

const experienceTiles = {
  hidden: { opacity: 0, x: -100, transition: { duration: 1 } },
  visible: { opacity: 1, x: 0, transition: { duration: 1, type: 'spring', stiffness: 50 } }
};

const Experience = () => {
  const { activeNav } = useContext(NavContext);
  const { t } = useTranslation();
  const isVisible = activeNav === '#experience';
  const animateProp = useAnimateOnce(isVisible);
  const [expandedArticleFrontend, setExpandedArticleFrontend] = useState(0);
  const [expandedArticleOther, setExpandedArticleOther] = useState(null);

  return (
    <section id='experience'>
      <motion.h5 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('experience__small-header')}
      </motion.h5>
      <motion.h2 variants={headerVariant} initial='hidden' animate={animateProp}>
        {t('experience__header')}
      </motion.h2>
      <div className='container experience__container'>
        <motion.div className='experience__frontend' variants={experienceTiles} initial='hidden' animate={animateProp}>
          <h3>Frontend Development</h3>
          <div className='experience__content'>
            {frontendItems.map((item) => (
              <Accordion
                key={item.id}
                item={item}
                expanded={item.id === expandedArticleFrontend}
                last={frontendItems.length - 1}
                isVisible={animateProp}
                onClick={() => setExpandedArticleFrontend(expandedArticleFrontend === item.id ? null : item.id)}
              />
            ))}
          </div>
        </motion.div>
        <motion.div className='experience__other' variants={experienceTiles} initial='hidden' animate={animateProp}>
          <h3>{t('experience__other')}</h3>
          <div className='experience__content'>
            {otherItems.map((item) => (
              <Accordion
                key={item.id}
                item={item}
                expanded={item.id === expandedArticleOther}
                last={otherItems.length - 1}
                isVisible={animateProp}
                onClick={() => setExpandedArticleOther(expandedArticleOther === item.id ? null : item.id)}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Experience;
