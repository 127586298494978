import React, { useContext } from 'react';
import { BiBook } from 'react-icons/bi';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import { BsChatLeftDots, BsBriefcase } from 'react-icons/bs';

import './nav.css';
import { NavContext } from '../../contexts/NavContext';
import LanguageSelector from '../language-selector/LanguageSelector';

const Nav = () => {
  const { activeNav, handleClick } = useContext(NavContext);

  return (
    <nav>
      <a
        href='#home'
        onClick={(event) => handleClick(event, '#home')}
        className={activeNav === '#home' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a
        href='#about'
        onClick={(event) => handleClick(event, '#about')}
        className={activeNav === '#about' ? 'active' : ''}>
        <AiOutlineUser />
      </a>
      <a
        href='#experience'
        onClick={(event) => handleClick(event, '#experience')}
        className={activeNav === '#experience' ? 'active' : ''}>
        <BiBook />
      </a>
      <a
        href='#portfolio'
        onClick={(event) => handleClick(event, '#portfolio')}
        className={activeNav === '#portfolio' ? 'active' : ''}>
        <BsBriefcase />
      </a>
      <a
        href='#contact'
        onClick={(event) => handleClick(event, '#contact')}
        className={activeNav === '#contact' ? 'active' : ''}>
        <BsChatLeftDots />
      </a>
      <LanguageSelector icon />
    </nav>
  );
};

export default Nav;
