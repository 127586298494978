import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import Loader from '../common/loader/Loader';

const Form = ({ loading, setLoading, success, setSuccess, error, setError }) => {
  const form = useRef();
  const { t } = useTranslation();

  const resetForm = () => {
    setLoading(false);
    setSuccess(false);
    setError(null);
  };

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const validateField = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        errorMessage = value.trim() === '' ? t('contact__error-name') : '';
        break;
      case 'email':
        if (value.trim() === '') {
          errorMessage = t('contact__error-email1');
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = t('contact__error-email2');
        }
        break;
      case 'message':
        errorMessage = value.trim() === '' ? t('contact__error-message') : '';
        break;
      default:
        break;
    }
    return errorMessage;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const errorMessage = validateField(name, value);
    setFormErrors({ ...formErrors, [name]: errorMessage });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formValues = {
      name: form.current.elements.name.value,
      email: form.current.elements.email.value,
      message: form.current.elements.message.value
    };

    const errors = {};
    Object.keys(formValues).forEach((key) => {
      errors[key] = validateField(key, formValues[key]);
    });
    setFormErrors(errors);

    if (Object.values(errors).some((errorMessage) => errorMessage !== '')) {
      return;
    }

    setLoading(true);
    try {
      await emailjs.sendForm('service_1nwwfss', 'template_te9fh3i', form.current, '8NGUkUQCmLbiu3a_6');
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }

    e.target.reset();
  };

  const inputs = form.current?.elements;

  if (error || success) {
    Array.from(inputs).forEach((input) => input.addEventListener('focus', resetForm));
  }

  return (
    <form ref={form} onSubmit={sendEmail} noValidate>
      <input
        disabled={loading}
        type='text'
        name='name'
        placeholder={t('contact__name-placeholder')}
        required
        onChange={handleInputChange}
        className={`first-field ${formErrors.name ? 'error-border' : ''}`}
      />
      {formErrors.name && <p className='error-message'>{formErrors.name}</p>}
      <input
        disabled={loading}
        type='email'
        name='email'
        placeholder={t('contact__email-placeholder')}
        required
        onChange={handleInputChange}
        className={formErrors.email ? 'error-border' : ''}
      />
      {formErrors.email && <p className='error-message'>{formErrors.email}</p>}
      <textarea
        disabled={loading}
        name='message'
        rows='7'
        placeholder={t('contact__message-placeholder')}
        required
        onChange={handleInputChange}
        className={formErrors.message ? 'error-border' : ''}
      />
      {formErrors.message && <p className='error-message'>{formErrors.message}</p>}
      <div className='contact__button-container'>
        {loading && <Loader />}
        {!loading &&
          !error &&
          (success ? (
            <p className='success'>{t('contact__success')}</p>
          ) : (
            <button type='submit' className='btn btn-primary'>
              {t('contact__submit-button')}
            </button>
          ))}
        {error && (
          <p className='error'>
            {t('contact__error-prefix')}
            {error.text}
          </p>
        )}
      </div>
    </form>
  );
};

Form.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  setSuccess: PropTypes.func.isRequired,
  error: PropTypes.object,
  setError: PropTypes.func.isRequired
};

export default Form;
