export const frontendItems = [
  {
    id: 0,
    title: 'React, TypeScript, MaterialUI, HTML, CSS, SaSS',
    description: 'experience__react'
  },
  {
    id: 1,
    title: 'PHP, WordPress, HTML, CSS',
    description: 'experience__wordpress'
  },
  {
    id: 2,
    title: 'React Native',
    description: 'experience__react-native'
  },
  {
    id: 3,
    title: 'Redux, React Testing Library',
    description: 'experience__redux'
  },
  {
    id: 4,
    title: 'Angular, C#, .NET, Microsoft Azure, SQL',
    description: 'experience__angular'
  },
  {
    id: 5,
    title: 'Agile, Scrum',
    description: 'experience__agile'
  }
];

export const otherItems = [
  {
    id: 0,
    title: 'Git',
    description: 'experience__git'
  },
  {
    id: 1,
    title: 'Jira, Trello',
    description: 'experience__jira'
  },
  {
    id: 2,
    title: 'Figma',
    description: 'experience__figma'
  },
  {
    id: 3,
    title: 'Canva, Gimp',
    description: 'experience__canva'
  }
];
